import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Educator from "../components/educator"
const logo = require("../images/whitelogo.svg").default

const ResearcherPage = () => (
  <Layout
    backgroundColor="#171b32"
    logo={logo}
    menuBGColor="#171b32"
    hamburgerColor="white"
    footerColor="#171b32"
  >
    <Seo title="Rajyashree Ramesh" />
    <Educator />
  </Layout>
)

export default ResearcherPage
