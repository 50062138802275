import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  DescriptionPara,
  VerticalLine,
  VerticalLineContainer,
} from "../commons/ImageDesc"
import {
  PosterContainer,
  PosterImageContainer,
  PosterTitle,
} from "../commons/PosterContainer.style"
import {
  CardTitle,
  ImageCardContainer,
  ImageContainer,
  ImageFadeIn,
} from "../commons/HighlightsCards.style"
import {
  BottomPaddingBlock,
  Title,
  Wrapper,
} from "../performer/performerStyles"
import { HighLightsContainer } from "../home/homestyles"

const Educator = () => (
  <React.Fragment>
    <PosterContainer backgroundColor="#171b32">
      <PosterTitle>EDUCATOR</PosterTitle>
      <PosterImageContainer width="862px">
        <ImageFadeIn>
          <StaticImage src="../../images/Educator/banner.svg" alt="Educator" />
          <CardTitle>
            Where teaching dance becomes
            <br /> educating through dance.
          </CardTitle>
        </ImageFadeIn>
      </PosterImageContainer>

      <DescriptionPara>
        Starting with the 14-year teaching assignment at the Community College
        of Berlin-Steglitz, that attracted many a non-Indian adult dance seeker
        from the early 1980’s into the 90’s, Rajyashree Ramesh developed
        innovative teaching methodologies that to this day facilitate learning
        for all ages, backgrounds and also capacities. These methodologies were
        further refined in the comprehensive course formats she offered at her
        own Academy for Performing Arts and Dance Theatre Productions from the
        90’s onwards. Several dancers groomed here for both stage and teaching
        over the decades have been claiming a place for themselves in the dance
        world, or are successfully incorporating the same into their respective
        professions. <a className="anchor-element" target="_blank" href="http://www.rrdance.net/de/alumni.html">See here </a>
      </DescriptionPara>
      <DescriptionPara>
        Central and unique in the pioneering work is not only an in-depth
        training in the traditional vocabulary and repertoire of Bharatanatyam,
        but harnessing the educating potential dance holds. She has facilitated
        innumerous dance and dance theatre courses, workshops and outreach
        projects at community colleges, Die Etage State School for Acting, Mime,
        Dance, Ballet Schools, schools, Museums (such as Ethnological Museums in
        Berlin and Leipzig) and libraries. In the last several years also an
        invited guest teacher/lecturer at professional training programmes for
        actors, narrators and movement analysts.
      </DescriptionPara>
      <DescriptionPara>
        After an in-depth study of human movement and supported by academic
        research, her pathbreaking movement analytic-fascial approach in the
        mean-time makes specific aesthetics in dance forms like Bharatanatyam
        accessible to an even wider range of learners. She is currently Docent
        and Coordinator for Dance at the Global Music Academy in Berlin, where
        she has launched a Professional Diploma Programme, the first of its kind
        Dance and Movement Studies coined “Bharata-to-Bartenieff” that she
        developed during several years of research. A vision for the future that
        she now trains professional dancers in.
      </DescriptionPara>
      <BottomPaddingBlock />
    </PosterContainer>
    <VerticalLineContainer>
      <VerticalLine />
    </VerticalLineContainer>

    <Wrapper>
      <Title>
        Grace, precision, subtle expression, eloquence in communication, clarity
        in concept, a penchant for narratives and an inherent knowledge of
        music.
      </Title>
    </Wrapper>
    <HighLightsContainer>
      <ImageCardContainer gap="0">
        <ImageContainer>
          <StaticImage src="../../images/Educator/educator-img.png" alt="" />
        </ImageContainer>
      </ImageCardContainer>
    </HighLightsContainer>
  </React.Fragment>
)

export default Educator
